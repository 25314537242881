import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { compose } from 'redux';
import {
  BrandingIconCard,
  Button,
  IconSpinner,
  Loader,
  Modal,
  NamedLink,
  NamedRedirect,
  PaginationLinks,
} from '../../components';
import IconCollection from '../../components/IconCollection/IconCollection';
import LayoutSingleColumn from '../../components/LayoutComposer/LayoutSingleColumn/LayoutSingleColumn';
import Page from '../../components/Page/Page';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { mixPanelEventsConstants, mixPanelEventsScreens } from '../../mixpanel';
import { isBookingProcess, resolveLatestProcessName } from '../../transactions/transaction';
import { transitions as dealTransitions } from '../../transactions/transactionProcessDeal';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE, STAFF_LISTING_TYPE } from '../../util/types';
import { InboxItem } from '../InboxPage/InboxPage';
import { getStateData } from '../InboxPage/InboxPage.stateData';
import { getOwnListingsById } from '../ManageListingsPage/ManageListingsPage.duck';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './DashboardPage.module.css';
import DashboardData from '../ViewListing/DashboardData';



const BrandingIconTypes = {
  DEALS: 'deals',
  SOLD: 'sold',
  TRENDUP: 'trentup',
  TOTAL: 'total',
  ACTIVE_ITEMS: 'active-items',
  ACTIVE_DEALS: 'active-deals'
};

const badgeIcon = {
  BUCKET: 'bucket',
  BUZZ: 'buzz',
  SCANQR: 'scanqr',
  ACCEPTCODE: 'acceptcode',
};

const DashboardPageComponent = props => {
  const config = useConfiguration();
  const routes = useRouteConfiguration();
  const history = useHistory();
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    providerNotificationCount,
    scrollingDisabled,
    allTransactions,
    onManageDisableScrolling,
    transactions,
    appState,
    listings = [],
    fetchAnalyticsRequest,
    fetchAnalyticsSuccess,
    fetchAnalyticsError,
  } = props;

  const {
    top_4_services,
    sales,
    revenue,
    today_sales,
    completed_items,
    purchased_items,
    filtered_sales,
    filtered_revenue,
    filtered_today_sales,
    filtered_completed_items,
    filtered_purchased_items,
    staff_details,
    weekly_payments,
  } = fetchAnalyticsSuccess || {};

  const pieChartData = [
    ['Status', 'Count'],
    ['Done', completed_items * 100],
    ['Paid', purchased_items * 100],
  ];

  const pieChartOptions = {
    // title: "My Daily Activities",
    // pieHole: 0.4, // Creates a Donut Chart. Does not do anything when is3D is enabled
    // is3D: true, // Enables 3D view
    // slices: {
    //   1: { offset: 0.2 }, // Explodes the second slice
    // },
    // pieStartAngle: 100, // Rotates the chart
    sliceVisibilityThreshold: 0.02, // Hides slices smaller than 2%
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 12,
      },
    },
    colors: ["#FFC416", "#70B6C1", "#FFB1B7", "#BCD18A", "#D1C28A"],
  };

  const transformedServiceData = Array.isArray(top_4_services) && top_4_services?.length > 0
    ? [
      ["Item", "Revenue"], // Headers
      ...top_4_services.map(service => [
        service?.title,
        service?.revenue,
      ])
    ]
    : [["Item", "Revenue"]];  // If no data, just show headers

  const top4ServicesOptions = {
    pieHole: 0.4, //  Creates a Donut Chart. Does not do anything when is3D is enabled
    sliceVisibilityThreshold: 0.02, // Hides slices smaller than 2%
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 12,
      },
    },
    colors: ["#FFC416", "#70B6C1", "#FFB1B7", "#BCD18A", "#D1C28A"],
  }

  // Transform the data with the day as "Mon"
  const transformedWeeklyData = Array.isArray(weekly_payments) && weekly_payments?.length > 0
    ? [
      ["Day", "Revenue"],
      ...weekly_payments.map(payment => [
        moment(payment?.date).format("ddd"), // Format the date to display the day (Mon, Tue, etc.)
        payment?.total
      ])
    ]
    : [["Day", "Revenue"]];


  const areaChartOptions = {
    isStacked: true,
    height: 300,
    legend: { position: "top", maxLines: 3 },
    vAxis: { minValue: 0 },
    areaOpacity: 0.1,
    colors: ["#ECBC30", "#70B6C1"],
  };

  // Find Total Deals
  const totalDeals = listings.length;

  // Find Active Deals
  const activeDeals = listings.filter(deal => {
    const { deal_end_date } = deal.attributes.publicData || {};
    return moment.unix(deal_end_date).isAfter(moment());
  });

  const draftId = '00000000-0000-0000-0000-000000000000';
  const draftSlug = 'draft';
  const currentUserId = currentUser?.id.uuid;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const {
    userType,
    isProductServiceListingPublished,
    isDealListingPublished,
    isBusinessListingPublished,
    profileType,
    isPhoneNumberVerified,
    destinationId = '',
  } = currentUser?.attributes?.profile?.publicData || {};

  const isOnboardingComplete =
    isProductServiceListingPublished && isDealListingPublished && isBusinessListingPublished;
  if (!isPhoneNumberVerified || !profileType) {
    return <NamedRedirect name="MerchantDetails" />;
  }

  useEffect(() => {
    if (isPhoneNumberVerified && isOnboardingComplete && profileType && destinationId == '') {
      setShowPaymentModal(true);
    }
    mixpanel.track(mixPanelEventsConstants.VIEW_MAIN_DASHBOARD, {
      screenName: mixPanelEventsScreens.HOME,
      userID: currentUserId,
      userType: 'Provider',
    });
  }, []);

  const hasNoResults = !fetchInProgress && allTransactions.length === 0 && !fetchOrdersOrSalesError;

  const date = new Date();
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Filter Today's transactions
  const filteredTransactions = transactions.filter(transaction => {
    const lastTransitionDateStr = transaction?.attributes?.lastTransitionedAt;

    if (lastTransitionDateStr) {
      const lastTransitionDate = new Date(lastTransitionDateStr);
      const formattedLastTransitionDate = lastTransitionDate.toLocaleDateString('en-US', options);
      return formattedLastTransitionDate === formattedDate;
    }
    return false;
  });

  // Calculate Today's sales in cents
  // const soldToday = filteredTransactions.reduce((total, transaction) => {
  //   const saleAmount = transaction?.attributes?.payinTotal?.amount || 0;
  //   return total + saleAmount;
  // }, 0);

  const soldToday = filteredTransactions?.length || 0

  // Calculate Total sales in cents
  // const totalSales = transactions.reduce((total, transaction) => {
  //   const saleAmount = transaction?.attributes?.payinTotal?.amount || 0;
  //   return total + saleAmount;
  // }, 0);

  const totalSales = transactions?.length || 0;

  const pickType = lt => conf => conf.listingType === lt;
  const findListingTypeConfig = publicData => {
    const listingTypeConfigs = config.listing?.listingTypes;
    const { listingType } = publicData || {};
    const foundConfig = listingTypeConfigs?.find(pickType(listingType));
    return foundConfig;
  };

  const hasOrderOrSaleTransactions = (tx, user) => {
    return user?.id && tx && tx.length > 0 && tx[0].provider.id.uuid === user?.id?.uuid;
  };

  const hasTransactions = !fetchInProgress && hasOrderOrSaleTransactions(transactions, currentUser);

  const toTxItem = tx => {
    const transactionRole = 'provider';
    let stateData = null;
    try {
      stateData = getStateData({ transaction: tx, transactionRole, intl });
    } catch (error) {
      // If stateData is missing, omit the transaction from InboxItem list.
    }

    const publicData = tx?.listing?.attributes?.publicData || {};
    const foundListingTypeConfig = findListingTypeConfig(publicData);
    const { transactionType, stockType } = foundListingTypeConfig || {};
    const process = tx?.attributes?.processName || transactionType?.transactionType;
    const transactionProcess = resolveLatestProcessName(process);
    const isBooking = isBookingProcess(transactionProcess);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          transactionRole={transactionRole}
          tx={tx}
          intl={intl}
          stateData={stateData}
          stockType={stockType}
          isBooking={isBooking}
        />
      </li>
    ) : null;
  };

  const listingType = DEAL_LISTING_TYPE;

  const listingTypeHeading = {
    [DEAL_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.deals" }),
    [SERVICE_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.services" }),
    [PRODUCT_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.products" }),
    [STAFF_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.staffs" }),
  }


  return (
    <Page title={''} scrollingDisabled={scrollingDisabled} referrer="origin">
      <LayoutSingleColumn
        className={css.mainLayout}
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
      >
        <div className={classNames(css.dashboardWrapper, arabicLanguage && css.arabicText)}>
          <div className={css.dashboardHeader}>
            <div className={classNames(css.dashboardHeading, arabicLanguage && css.arabicText)}>
              <FormattedMessage id="DashboardPage.hello" /> {currentUser?.attributes?.profile?.firstName},
            </div>
            <div className={classNames(css.dashboardSubHeading, arabicLanguage && css.arabicText)}>
              <FormattedMessage id="DashboardPage.summary" />
            </div>
          </div>

          {/* Dashboard Data */}
          <div className={css.dashboardBody}>
            <DashboardData
              activeDeals={activeDeals}
              totalDeals={totalDeals}
              totalSales={totalSales}
              soldToday={soldToday}
              listingType={"none"}
              intl={intl}
              fetchAnalyticsRequest={fetchAnalyticsRequest}
              analyticsData={fetchAnalyticsSuccess}
              fetchAnalyticsError={fetchAnalyticsError}
              currentUser={currentUser}
            />

            <div className={css.bookingOrder}>
              <div className={classNames(css.bookingHeader)}>
                <div className={css.bookingHeading}>
                  {intl.formatMessage({ id: 'DashboardPage.latestBookings' })}
                </div>
              </div>
              <div className={css.tableWrapper}>
                <ul className={classNames(css.itemList, arabicLanguage && css.arabicText)}>
                  <div className={classNames(css.bookingTable)}>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {intl.formatMessage({ id: "InboxPage.imageHeading" })}
                          </th>
                          <th>
                            {intl.formatMessage({ id: "InboxPage.nameHeading" })}
                          </th>
                          <th>
                            {intl.formatMessage({ id: "InboxPage.titleHeading" })}
                          </th>
                          <th>
                            {intl.formatMessage({ id: 'InboxPage.numberOfItemsHeading' })}
                          </th>
                          <th>
                            {intl.formatMessage({ id: "InboxPage.statusHeading" })}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  {!fetchInProgress ? (
                    allTransactions.map(toTxItem)
                  ) : (
                    <li className={css.listItemsLoading}>
                      <IconSpinner />
                    </li>
                  )}
                  {hasNoResults ? (
                    <li key="noResults" className={css.noResults}>
                      <FormattedMessage id={'InboxPage.noSalesFound'} />
                    </li>
                  ) : null}
                </ul>
              </div>
              {hasTransactions && pagination && pagination.totalPages > 1 ? (
                <PaginationLinks
                  className={css.pagination}
                  pageName="LandingPage"
                  // pagePathParams={params}
                  pagination={pagination}
                />
              ) : null}
            </div>
          </div>

          <Modal
            className={css.importantUpdateModal}
            id="ImportantUpdateModal"
            isOpen={showPaymentModal}
            onClose={() => setShowPaymentModal(false)}
            usePortal
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.container}>
              <div className={css.icon}>
                <IconCollection name="important_update" />
              </div>
              <h2 className={css.title}>
                <FormattedMessage id="DashboardPage.importantUpdate" />
              </h2>
              <p className={css.description}>
                <FormattedMessage id="DashboardPage.youNeed" />
              </p>
              <ul className={css.requirements}>
                <li>
                  <FormattedMessage id="DashboardPage.iDDocument" />
                </li>
                <li>
                  {' '}
                  <FormattedMessage id="DashboardPage.bankCertificate" />
                </li>
                <li>
                  {' '}
                  <FormattedMessage id="DashboardPage.commercialRegistration" />
                </li>
                <li>
                  {' '}
                  <FormattedMessage id="DashboardPage.businessAccount" />
                </li>
                <li>
                  <FormattedMessage id="DashboardPage.codeAccount" />
                </li>
                <li>
                  {' '}
                  <FormattedMessage id="DashboardPage.personDetails" />
                </li>
                <li>
                  {' '}
                  <FormattedMessage id="DashboardPage.brandName" />
                </li>
              </ul>
              <div className={css.actions}>
                <button
                  className={css.addButton}
                  onClick={() => {
                    setShowPaymentModal(false);
                    history.push(createResourceLocatorString('StripePayoutPage', routes, {}, {}));
                  }}
                >
                  <FormattedMessage id="DashboardPage.paymentDetails" />
                </button>

                <button className={css.updateLater} onClick={() => setShowPaymentModal(false)}>
                  <FormattedMessage id="DashboardPage.updateLater" />
                </button>
              </div>
            </div>
          </Modal>
          {userType === 'provider' && !isOnboardingComplete ? (
            <Modal
              className={css.completeOnboardingModal}
              id="VerifyDeletingPaymentMethod"
              isOpen={isModalOpen}
              onClose={() => {
                // setIsModalOpen(false);
              }}
              usePortal
              contentClassName={css.modalContent}
              onManageDisableScrolling={onManageDisableScrolling}
              showCloseBtn={false}
            >
              <div>
                <div className={css.completeOnboardingText}>
                  <FormattedMessage id="TopbarDesktop.completeOnboarding" />
                </div>
                <NamedLink name="MerchantOnboardingPage" className={css.topbarLink}>
                  <Button type="button">
                    <FormattedMessage id="TopbarDesktop.continueButtonText" />
                  </Button>
                </NamedLink>
              </div>
            </Modal>
          ) : null}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};
DashboardPageComponent.defaultProps = {};
DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    currentPageResultIds,
    fetchAnalyticsRequest,
    fetchAnalyticsSuccess,
    fetchAnalyticsError,
  } = state.LandingPage;

  const {
    // currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ViewListingsPage;

  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  const listings = getOwnListingsById(state, currentPageResultIds);

  const transactions = getMarketplaceEntities(state, transactionRefs);

  const allTransactions = transactions.filter(
    tx =>
      !tx?.attributes?.transitions?.some(
        t =>
          t.transition === dealTransitions.CHILD_PRODUCT_REQUEST_PAYMENT ||
          t.transition === dealTransitions.CHILD_SERVICE_REQUEST_PAYMENT
      )
  );

  const filteredTransactions = allTransactions.filter(
    tx => ["default-deal-booking", "default-deal-purchase"].includes(tx?.attributes?.processName)
  );

  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: filteredTransactions,
    allTransactions: allTransactions,
    appState: state,
    currentPageResultIds,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    listings,
    fetchAnalyticsRequest,
    fetchAnalyticsSuccess,
    fetchAnalyticsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const DashboardPage = compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(DashboardPageComponent);
export default DashboardPage;
