
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Chart } from 'react-google-charts';
import flyerPdf from '../../assets/files/NowFlyerEng.pdf';
import { BrandingIconCard, Loader, NamedLink } from '../../components';
import { mixPanelEventsScreens } from '../../mixpanel';
import { FormattedMessage } from '../../util/reactIntl';
import {
  DEAL_LISTING_TYPE,
  PRODUCT_LISTING_TYPE,
  SERVICE_LISTING_TYPE,
  STAFF_LISTING_TYPE
} from '../../util/types';
import css from './DashboardData.module.css';

const BrandingIconTypes = {
  DEALS: 'deals',
  SOLD: 'sold',
  TRENDUP: 'trentup',
  TOTAL: 'total',
  ACTIVE_ITEMS: 'active-items',
  ACTIVE_DEALS: 'active-deals',
  HEALTH: 'health',
};

const badgeIcon = {
  BUCKET: 'bucket',
  BUZZ: 'buzz',
  SCANQR: 'scanqr',
  ACCEPTCODE: 'acceptcode',
  PEOPLE: 'people',
  WALLET: 'wallet',
  SHARE_LINK: "share-link"
};

const DashboardData = (props) => {
  const {
    activeDeals = [],
    totalDeals,
    totalSales,
    soldToday,
    listingType = 'none',
    intl,
    fetchAnalyticsRequest,
    analyticsData,
    fetchAnalyticsError,
    currentUser
  } = props;

  const isDeal = listingType === DEAL_LISTING_TYPE;
  const isHomePage = listingType === "none";
  const currentUserId = currentUser?.id.uuid;

  const {
    top_4_services,
    sales,
    revenue,
    today_sales,
    completed_items,
    purchased_items,
    filtered_sales,
    filtered_revenue,
    filtered_today_sales,
    filtered_completed_items,
    filtered_purchased_items,
    staff_details,
    weekly_payments,
  } = analyticsData || {};

  const pieChartData = [
    ['Status', 'Count'],
    ['Done', isHomePage ? completed_items : filtered_completed_items],
    ['Paid', isHomePage ? purchased_items : filtered_purchased_items],
  ];

  const pieChartOptions = {
    sliceVisibilityThreshold: 0.02, // Hides slices smaller than 2%
    legend: {
      position: "bottom",
      alignment: "center",
      textStyle: {
        color: "#233238",
        fontSize: 12,
      },
    },
    colors: ["#FFC416", "#70B6C1", "#FFB1B7", "#BCD18A", "#D1C28A"],
  };

  const transformedServiceData = Array.isArray(top_4_services) && top_4_services?.length > 0
    ? [
      ["Item", "Revenue"], // Headers
      ...top_4_services.map(service => [
        service?.title,
        service?.revenue,
      ])
    ]
    : [["Item", "Revenue"]];  // If no data, just show headers

  const top4ServicesOptions = {
    pieHole: 0.6, //  Creates a Donut Chart. Does not do anything when is3D is enabled
    sliceVisibilityThreshold: 0.02, // Hides slices smaller than 2%
    legend: "none",
    colors: ["#FFC416", "#70B6C1", "#FFB1B7", "#BCD18A", "#D1C28A"],
  }

  // Transform the data with the day as "Mon"
  const transformedWeeklyData = Array.isArray(weekly_payments) && weekly_payments?.length > 0
    ? [
      ["Day", "Revenue"],
      ...weekly_payments.map(payment => [
        moment(payment?.date).format("ddd"), // Format the date to display the day (Mon, Tue, etc.)
        payment?.total
      ])
    ]
    : [["Day", "Revenue"]];


  const areaChartOptions = {
    title: "Weekly Revenue",
    isStacked: true,
    height: 300,
    // legend: { position: "top", maxLines: 3 },
    vAxis: { minValue: 0 },
    areaOpacity: 0.1,
    colors: ["#ECBC30", "#70B6C1"],
  };

  const listingTypeHeading = {
    [DEAL_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.deals" }),
    [SERVICE_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.services" }),
    [PRODUCT_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.products" }),
    [STAFF_LISTING_TYPE]: intl.formatMessage({ id: "DashboardPage.staffs" }),
  }
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  if (fetchAnalyticsRequest) {
    return <Loader />
  }

  const handleFlyerDownload = () => {
    const link = document.createElement('a');
    link.href = flyerPdf;
    link.download = 'NOW_BEGINNERS_GUIDE.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={classNames(css.dashboardWrapper, arabicLanguage && css.arabicText)}>
      <div className={css.dashboardBody}>

        {fetchAnalyticsError ? (
          <div className={css.error}>
            <FormattedMessage id="DashboardData.errorMessage" />
          </div>
        ) : null}

        <div className={css.flyerBanner} onClick={handleFlyerDownload}>
          <h4><FormattedMessage id="DashboardData.getStartedWithNow" /></h4>
          <p><FormattedMessage id="DashboardData.clickToDownload" /></p>
        </div>

        <div className={css.dealsCardsWrapper}>
          <div className={css.dealCard}>
            <div className={css.cardLeft}>
              <div className={css.number}>{activeDeals.length}</div>
              <div className={css.heading}>
                <FormattedMessage id="DashboardPage.activeItems"
                  values={{ listingType: listingTypeHeading?.[listingType] ? listingTypeHeading?.[listingType] : intl.formatMessage({ id: "DashboardPage.items" }) }} />
              </div>
            </div>
            <div className={css.cardRight}>
              <BrandingIconCard type={isDeal ? BrandingIconTypes.ACTIVE_DEALS : BrandingIconTypes.ACTIVE_ITEMS} />
            </div>
          </div>
          <div className={css.dealCard}>
            <div className={css.cardLeft}>
              <div className={css.number}>{isHomePage ? today_sales : filtered_today_sales}</div>
              <div className={css.heading}>
                <FormattedMessage id="DashboardPage.itemsSoldToday"
                  values={{ listingType: listingTypeHeading?.[listingType] ? listingTypeHeading?.[listingType] : intl.formatMessage({ id: "DashboardPage.items" }) }} />
              </div>
            </div>
            <div className={css.cardRight}>
              <BrandingIconCard type={isDeal ? BrandingIconTypes.HEALTH : BrandingIconTypes.SOLD} />
            </div>
          </div>
          <div className={css.dealCard}>
            <div className={css.cardLeft}>
              <div className={css.number}>{totalDeals}</div>
              <div className={css.heading}>
                <FormattedMessage id="DashboardPage.totalItems"
                  values={{ listingType: listingTypeHeading?.[listingType] ? listingTypeHeading?.[listingType] : intl.formatMessage({ id: "DashboardPage.items" }) }} />
              </div>
            </div>
            <div className={css.cardRight}>
              <BrandingIconCard type={BrandingIconTypes.TRENDUP} />
            </div>
          </div>
          <div className={css.dealCard}>
            <div className={css.cardLeft}>
              <div className={css.number}>{isHomePage ? sales : filtered_sales}</div>
              <div className={css.heading}>
                <FormattedMessage id="DashboardPage.totalItemSales"
                  values={{ listingType: listingTypeHeading?.[listingType] ? listingTypeHeading?.[listingType] : intl.formatMessage({ id: "DashboardPage.items" }) }} />
              </div>
            </div>
            <div className={css.cardRight}>
              <BrandingIconCard type={BrandingIconTypes.TOTAL} />
            </div>
          </div>
        </div>

        <div className={css.badgeCardBox}>
          <NamedLink className={css.link} name="BookingGuidePage">
            <div className={classNames(css.badgeCard)} onClick={() => {
              mixpanel.track('create_deal', {
                screenName: mixPanelEventsScreens.HOME,
                userID: currentUserId,
                userType: 'Provider',
              });
            }}>
              <div>
                <BrandingIconCard type={badgeIcon.SHARE_LINK} />
              </div>
              <div className={css.heading}>{intl.formatMessage({ id: "DashboardPage.shareBookingLink" })}</div>
            </div>
          </NamedLink>
          <NamedLink className={css.link} name="StripePayoutPage">
            <div onClick={() => {
              mixpanel.track('send_buzz', {
                screenName: mixPanelEventsScreens.HOME,
                userID: currentUserId,
                userType: 'Provider',
              });
            }} className={classNames(css.badgeCard)}>
              <div>
                <BrandingIconCard type={badgeIcon.WALLET} />
              </div>
              <div className={css.heading}>{intl.formatMessage({ id: "DashboardPage.payoutDetails" })}</div>
            </div>
          </NamedLink>
          <NamedLink className={css.link} name="MerchantFlyerPage">
            <div className={classNames(css.badgeCard)}>
              <div>
                <BrandingIconCard type={badgeIcon.SCANQR} />
              </div>
              <div className={css.heading}>{intl.formatMessage({ id: "DashboardPage.getQR" })}</div>
            </div>
          </NamedLink>
          <NamedLink className={css.link} name="ViewListingsPage" params={{ listingType: "staff" }}>
            <div className={classNames(css.badgeCard)}>
              <div>
                <BrandingIconCard type={badgeIcon.PEOPLE} />
              </div>
              <div className={css.heading}>{intl.formatMessage({ id: "DashboardPage.manageStaff" })}</div>
            </div>
          </NamedLink>
        </div>

        {/* Analytics and charts */}
        <div className={css.cartList}>
          <div className={css.ratioCard}>
            <div className={css.heading}>
              <FormattedMessage id="DashboardData.fulfillmentRatio" />

              <span className={css.threeDot}>
                <BrandingIconCard type="threedot" />
              </span>
            </div>
            <div className={css.subHeading}>
              <FormattedMessage id="DashboardData.thisWeek" />

            </div>
            <div className={css.chartBox}>
              <Chart
                chartType="PieChart"
                data={pieChartData}
                options={pieChartOptions}
                width={'100%'}
                height={'100%'}
              />
            </div>
          </div>
          <div className={css.ratioCard}>
            <div className={css.heading}>
              <FormattedMessage id="DashboardData.salesVolume" />
              <span className={css.threeDot}>
                <BrandingIconCard type="threedot" />
              </span>
            </div>
            <div className={css.chartBox}>
              {Array.isArray(top_4_services) && top_4_services.length > 0 ?
                top_4_services.map((service, index) => {
                  const dynamicClass = `service${index + 1}`; // Dynamically create class name like service1, service2, etc.
                  return (
                    <div key={index} className={classNames(css.service, css[dynamicClass])}>
                      <div className={css.dot}></div>
                      <div>
                        <div className={css.title}>{service?.title}</div>
                        <div className={css.revenue}>{`S${service?.revenue?.toFixed(2)}`}</div>
                      </div>
                    </div>
                  );
                })
                : null
              }

              <div className={css.totalRevenue}>
                <FormattedMessage id="DashboardData.totalRevenue" />
                <span className={css.totalAmount}>{`SAR ${(revenue / 1000)?.toFixed(2)}K`}</span>
              </div>

              <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={transformedServiceData}
                options={top4ServicesOptions}
              />
            </div>
          </div>
          <div className={css.ratioCard}>
            <div className={css.heading}>
              <FormattedMessage id="DashboardData.totalRevenue" />

              <span className={css.threeDot}>
                <BrandingIconCard type="threedot" />
              </span>
            </div>
            <p>SAR {parseFloat(isHomePage ? revenue : filtered_revenue).toFixed(2)}</p>
            <div className={css.chartBox}>
              <Chart
                chartType="AreaChart"
                width="100%"
                height="100%"
                data={transformedWeeklyData}
                options={areaChartOptions}
              />
            </div>
          </div>
        </div>

        <div className={css.bookingOrder}>
          <div className={css.bookingHeader}>
            <div className={css.bookingHeading}>
              {/* {intl.formatMessage({ id: "DashboardPage.latestBookings" })} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardData;
